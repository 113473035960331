<template>
  <div></div>
</template>

<script>
import router from "@/router";
import liff from "@line/liff";
import LiffChecker from "@/utils/LiffChecker";

export default {
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const orgCode = this.$route.params.org_code
      const checker = new LiffChecker(orgCode)
      await checker.initLiff()

      let params = new URL(location.href).searchParams;

      if (params.get('liff.state')) {
        router.push('/' + orgCode + '/liff' + params.get('liff.state'));
      } else {
        liff.closeWindow();
      }
    }
  }
}
</script>
